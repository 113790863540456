/*
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-10-24 18:34:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-25 21:56:54
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/router";
import http from "./http/http";
import config from './config/install_config'
import "@/assets/app.css";
import 'vant/lib/index.css';
import { Button } from 'vant'; 
import { Icon } from 'vant';

const app = createApp(App);


app.use(Button);
app.use(Icon);

app.use(router);
app.use(http);
app.use(config);

app.mount('#app')
