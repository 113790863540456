/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-10-24 18:54:50
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-25 00:29:09
 */
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/seller/pay",
    name: "seller_pay",
    component: () => import("@/views/seller/wap_pay"),
  },
  {
    path: "/seller/oauth",
    name: "seller_oauth",
    component: () => import("@/views/seller/oauth_response"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

export default router;
