/*
 * @Description: 配置参数
 * @Author: 琢磨先生
 * @Date: 2022-10-24 18:59:21
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-25 22:50:01
 */
export default {
  /**
   * api请求host地址
   */
  HOST:
    process.env.NODE_ENV === "development"
      ? "http://localhost:5000"
      : "https://api.liangtingkeji.com",
  /**
   * 项目app key
   */
  APP_KEY: "pms_18409abe00d",
};
