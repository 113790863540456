/*
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-10-24 19:04:05
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-24 19:04:26
 */
import axios from "axios";
import cfg from "@/config/config.js";

let config = {
    // baseURL: "http://pms-api.dexindi.com",
    baseURL: cfg.HOST,
    timeout: 60000, // Timeout
  };
  
  const _axios = axios.create(config);
  
  //请求开始时链接
  _axios.interceptors.request.use(
    (config) => {
      //平台应用key
      config.headers.app = cfg.APP_KEY;
  
      //从cookies中获取token写入请求头
    //   const token = Cookies.get(cfg.TOKEN);
    //   if (token) {
    //     config.headers.token = token;
    //   }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  _axios.interceptors.response.use(
    (response) => {
      console.log(response.config.url, response.data);
      //未登录或已过期
    //   if (response.data.code == 40101) {
    //     router.replace({
    //       name: "login",
    //     });
    //   } else if (response.data.code != 0) {
    //     ElMessage.error(response.data.msg);
    //   }
  
      return response.data;
    },
    (error) => {
    //   var message = `网络请求错误，状态码：${error.response.status}`;
    //   switch (error.response.status) {
    //     case 403:
    //       message = "没有权限，拒绝访问";
    //       break;
    //   }
    //   ElMessage.error(message);
      return Promise.reject(error);
    }
  );
  export default _axios;
  