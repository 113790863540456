/*
 * @Description: http 请求注册
 * @Author: 琢磨先生
 * @Date: 2022-10-24 19:00:02
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-24 19:04:12
 */
import _axios from './axios_init.js';


const install = (app) => {
   
    app.config.globalProperties.$http = _axios;
  };
  
  export default { install };
  