/*
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-10-24 19:07:38
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-24 19:07:43
 */

import config from "./config";

const install = (app) => {
  /**
   * 全局配置
   */
  app.config.globalProperties.$cfg = config;
};

export default { install };
